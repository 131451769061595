<template>
    <div>
        <div class="share-input-card container">
            <div class="columns">
                <div class="column"></div>
                <CardTitle class="share" :text="title" />
                <div class="column"></div>
            </div>

            <slot></slot>
        </div>

        <o-button 
            type="primary" 
            class="o-btn--primary input-confirm" 
            :label="sendButtonText"
            @click.native="$emit('share')">
        </o-button>
    </div>
</template>

<script>

import CardTitle from "@/components/CardTitle.vue";
import oButton from '@/objects/o-button/o-button.vue';

export default {
  name: "ShareInputCard",
  components: {
    CardTitle,
    'o-button': oButton
  },
  props: {
    title: {
        type: String,
        required: true
    },
    sendButtonText: {
        // The text to be placed on the send button
        type: String,
        required: true
    },
    sendButtonLoader: {
        type: Boolean,
        required: false,
        default: false,
    },
  }
};

</script>

<style lang="scss" scoped>

.share-input-card {
    background-color: white;
    padding-top: 64px;
    padding-bottom: 96px;
    border-radius: $cardRadius;
}

.input-confirm {
    bottom: 3rem;
    position: relative;
}

.share-input-card input {
    height: 85px;
    border: 4px solid #003865;
    font-family: 'Colfax-Regular';
    font-size: 20px;
    padding-left: 24px;
    padding-right: 24px;
    width: 85%;
}

</style>