<template>
  <div class="share-container">
    <Modal
      v-if="showModal"
      @close="$router.push({name: 'share-video'})"
      :header="getEmsDataPropertyText('video_sent_modal_title_page5')"
      :confirmText="getEmsDataPropertyText('video_sent_modal_done_button_page5')"
      confirmLink="/"
    >
      <video
        height="480"
        width="840"
        :src="content.shared_video"
        autoplay
        @ended="$router.push({name: 'share-video'})"
      ></video>
    </Modal>

    <router-link :to="{name: 'share-video'}">
      <o-button 
        type="grey" 
        class="o-btn--grey back-button" 
        :label="getEmsDataPropertyText('back')">
      </o-button>
    </router-link>

    <div class="content-container">
      <ShareInputCard
        class="share-card"
        :title="getEmsDataPropertyText(this.shareCardContext.headerID)"
        :sendButtonText="getEmsDataPropertyText(this.shareCardContext.confirmTextID)"
        :sendButtonLoader="sendButtonLoader"
        @share="sendSmsOrEmail"
      >
        <input
          :value="input"
          class="input"
          ref="input"
          @input="onInputChange"
          :placeholder="getEmsDataPropertyText(this.shareCardContext.placeholderID)"
        />
      </ShareInputCard>

      <SimpleKeyboard @onChange="onChange" @onKeyPress="onKeyPress" :input="input" :keyboardType="this.$route.params.shareViewType" />
    </div>
  </div>
</template>

<script>
import Modal from "@/components/Modal.vue";
import SimpleKeyboard from "@/components/SimpleKeyboard";
import ShareInputCard from "@/components/ShareInputCard.vue";
import { sendSms, sendEmail } from "@/BackendClient.js";
import oButton from '@/objects/o-button/o-button.vue';

export default {
  name: "InputContactDetails",
  components: {
    Modal,
    SimpleKeyboard,
    ShareInputCard,
    'o-button': oButton
  },
  data: () => ({
    input: "",
    showModal: false,
    shareCardContext: {
      headerID: "",
      placeholderID: "",
      confirmTextID: ""
    },
    sendButtonLoader: false,
  }),
  computed: {
    content() {
      return this.$store.getters.content;
    }
  },
  created() {
    if (this.$route.params.shareViewType == "sms") {
      this.shareCardContext = {
        headerID: "enter_sms_title_page5",
        placeholderID: "enter_sms_placeholder_page5",
        confirmTextID: "send_by_sms_page4"
      };

    } else if (this.$route.params.shareViewType == "email") {
      this.shareCardContext = {
        headerID: "enter_email_title_page5",
        placeholderID: "enter_email_placeholder_page5",
        confirmTextID: "send_by_email_page4"
      };
    }
  },
  methods: {
    onChange(input) {
      this.input = input;
    },
    onKeyPress(button) {
      console.log("button", button);
    },
    onInputChange(input) {
      this.input = input.target.value;
    },
    sendSmsOrEmail() {
      this.sendButtonLoader = true;

      if (this.$route.params.shareViewType == "sms") {
        this.sendSmsToInputNumber();
      } else if (this.$route.params.shareViewType == "email") {
        this.sendEmailToInputEmail();
      }
    },
    sendSmsOrEmailRequestCompleted() {
      this.sendButtonLoader = false;
    },
    async sendSmsToInputNumber() {
      let self = this;
      let message_template = this.getEmsDataPropertyText("sms_template");
      let message_text = message_template.replace(
        "{{ link }}",
        this.$route.params.videoURL
      );
      try {
        let res = await sendSms(self.$refs.input.value, message_text);
        if(res) {
          self.showModal = true;
        }
      }catch (e) {
        this.$buefy.notification.open({
          duration: 3000,
          message: `Something went wrong`,
          position: 'is-top',
          type: 'is-danger',
          hasIcon: true
        })

        this.sendSmsOrEmailRequestCompleted();
      }
    },
    async sendEmailToInputEmail() {
      let self = this;
      let message_template = this.getEmsDataPropertyText("sms_template");
      let message_text = message_template.replace(
        "{{ link }}", 
        this.$route.params.videoURL
      );
      try {
        let res = await sendEmail(
        self.$refs.input.value,
        self.getEmsDataPropertyText("email_subject"),
        message_text
      );
      if(res) {
        self.showModal = true;
      }
      }catch (e) {
        console.error(e);

        this.$buefy.notification.open({
          duration: 3000,
          message: `Something went wrong`,
          position: 'is-top',
          type: 'is-danger',
          hasIcon: true
        })

        this.sendSmsOrEmailRequestCompleted();
      }
    }
  }
};
</script>

<style lang="scss">
.content-container {
  position: absolute;
  bottom: 0%;
  width: 100%;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.share-container .back-button p {
  background-color: #999999;
}

.back-button a {
  color: white;
}

.back-button {
  position: fixed;
  left: 56px;
  top: 48px;
}

.content-container .simple-keyboard {
  overflow: visible;
  margin-right: 0px;
  margin-left: 0px;
  margin-bottom: 0px;
}

.hg-button {
  height: 10rem !important;
}

.hg-button span {
  font-size: 32px;
}

.notification  {
  .media-left {
    display: none;
  }
}

</style>
