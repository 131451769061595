<template>
  <div :class="keyboardClass" :style="this.keyboardType == 'sms' ? 'max-width: 30%; margin: 0 auto;' :  ''"></div>
</template>

<script>
import Keyboard from "simple-keyboard";
import "simple-keyboard/build/css/index.css";

export default {
  name: "SimpleKeyboard",
  props: {
    keyboardClass: {
      default: "simple-keyboard",
      type: String
    },
    input: {
      type: String
    },
    keyboardType: {
      type: String
    }
  },
  data: () => ({
    keyboard: null
  }),
  mounted() {
    const keyboardConf = {
      onChange: this.onChange,
      onKeyPress: this.onKeyPress
    }

    if (this.keyboardType == 'sms') {
      keyboardConf.layout = {
        default: ["1 2 3", "4 5 6", "7 8 9", "0 {bksp}"],
      }
    } else {
      keyboardConf.layout = {
        'default': [
          '1 2 3 4 5 6 7 8 9 0 {bksp}',
          'q w e r t y u i o p',
          'a s d f g h j k l',
          'z x c v b n m',
          '@ @gmail @outlook @hotmail @yahoo .com .nl . _ - +'
        ]
      }
    }

    this.keyboard = new Keyboard(keyboardConf);
  },
  methods: {
    onChange(input) {
      this.$emit("onChange", input);
    },
    onKeyPress(button) {
      this.$emit("onKeyPress", button);

      /**
       * If you want to handle the shift and caps lock buttons
       */
      if (button === "{shift}" || button === "{lock}") this.handleShift();
    },
    handleShift() {
      let currentLayout = this.keyboard.options.layoutName;
      let shiftToggle = currentLayout === "default" ? "shift" : "default";

      this.keyboard.setOptions({
        layoutName: shiftToggle
      });
    }
  },
  watch: {
    input(input) {
      this.keyboard.setInput(input);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.simple-keyboard {
  max-width: 100%;
  margin: auto;
  margin-top: 3rem;
  height: auto;
  padding: 30px !important;
}
.simple-keyboard.hg-theme-default .hg-button{
  height: 4rem !important;
}
</style>